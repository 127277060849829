<template>
  <li v-if="isLoaded" id="${juniorUid}" class="wish-candidate-card">
    <div class="wish-candidate-card-profile">
      <div class="wish-candidate-card-profile-image">
        <img
            v-if="profilePic"
            :src="profilePic"
            alt="avatar"
            class="round-image"
        />
        <img
            v-else
            alt="avatar"
            class="round-image"
            src="@/images/default-avatar.png"
        />
      </div>
      <div class="wish-candidate-card-profile-info">
        <h3>
          <u>{{ candidate.firstName + " " + candidate.lastName }}</u>
          requested a referral to
          <u>{{ wishData.position }}</u>
          at
          <u>{{ wishData.company }}</u
          >
          .
        </h3>
        <div class="profile-info-second-line">
          <h4>{{ candidate.major }}</h4>
          <h4 v-if="candidate.GPA">Cumulative GPA: {{ candidate.GPA }}</h4>
          <h4 v-else>This student does not have a GPA in profile.</h4>
        </div>
      </div>
      <div
          v-if="statusHolder == 'pending'"
          class="wish-candidate-card-profile-btns"
      >
        <div v-if="!confirmDecline&!confirmAccept" class="decline-accept">
          <button
              class="dashboard-detail-decline-btn"
              @click="confirmDecline = !confirmDecline;"
          >
            <p>Decline</p>
          </button>

          <button
              class="dashboard-detail-accept-btn"
              @click="confirmAccept = !confirmAccept"
          >
            <p>Grant</p>
          </button>
        </div>

        <div :class="{'confirm-btns-hidden':!confirmDecline&&!confirmAccept}" class="confirm-btns">
          <button
              v-if="confirmDecline"
              class="dashboard-detail-decline-btn confirm-decline"
              @click="
              changeStatus('declined');
              statusHolder = 'declined';
              confirmDecline = !confirmDecline;
            "
          >
            <p>Confirm Decline</p>
          </button>

          <button
              v-if="confirmAccept"
              class="dashboard-detail-accept-btn confirm-accept"
              @click="
              changeStatus('granted');
              statusHolder = 'granted';
              confirmAccept = !confirmAccept;
            "
          >
            <p @click="acceptCandidate">Confirm Grant</p>
          </button>
        </div>

      </div>
      <div
          v-if="statusHolder == 'granted'"
          class="wish-candidate-card-profile-status"
          @dragenter="
          changeStatus('pending');
          statusHolder = 'pending';
        "
          @mouseleave="resetTrigger = false"
          @mouseover="resetTrigger = false"
      >
        <div v-show="!resetTrigger" class="green">
          <ion-icon class="small-icon" name="checkmark-outline"></ion-icon>
        </div>
        <h3 v-show="!resetTrigger" class="green">Granted</h3>
        <div v-show="resetTrigger" class="grey">
          <ion-icon class="small-icon" name="sync-outline"></ion-icon>
        </div>
        <h3 v-show="resetTrigger" class="grey">Reset</h3>
      </div>
      <div
          v-if="statusHolder == 'declined'"
          class="wish-candidate-card-profile-status"
          @dragenter="
          changeStatus('pending');
          statusHolder = 'pending';
        "
          @mouseleave="resetTrigger = false"
          @mouseover="resetTrigger = false"
      >
        <div v-show="!resetTrigger" class="red">
          <ion-icon class="small-icon" name="close-outline"></ion-icon>
        </div>
        <h3 v-show="!resetTrigger" class="red">Declined</h3>
        <div v-show="resetTrigger" class="grey">
          <ion-icon class="small-icon" name="sync-outline"></ion-icon>
        </div>
        <h3 v-show="resetTrigger" class="grey">Reset</h3>
      </div>
    </div>
    <hr class="good-line-top"/>


    <div :class="{'collapse-content-container':true, 'collapse-content-containerFolded':isCollapsed}">
      <div class="profile-detail-content">
        <div class="about">
          <h3><u>About</u></h3>
          <h4>{{ candidate.about }}</h4>
        </div>
        <div class="exp-title">
          <h3><u>Experience</u></h3>
          <h4 v-if="candidate.experience.length == 0">
            This student has not added any experience yet.
          </h4>
        </div>
        <ul class="experience">
          <li v-for="exp in candidate.experience" :key="exp.id" class="my-exp">
            <div class="my-exp-top">
              <h2>{{ exp.title }}</h2>
              <h4 v-if="exp.currentInRole">
                {{ modifyDate(exp.startDate.toDate()) }} - Present
              </h4>
              <h4 v-if="!exp.currentInRole">
                {{ modifyDate(exp.startDate.toDate()) }} -
                {{ modifyDate(exp.endDate.toDate()) }}
              </h4>
            </div>
            <h4>{{ exp.company }}</h4>
            <h4>{{ exp.description }}</h4>
          </li>
        </ul>
      </div>
    </div>

    <hr :class="{'good-line-bot':true, 'line-invisible':isCollapsed}"/>
    <div class="expand-collapse-resume">
      <div
          class="expand-collapse collapsed"
          @click="isCollapsed = !isCollapsed"
      >
        <div class="expand-collapse-arrow">
          <ion-icon
              v-show="isCollapsed"
              class="up-down big-icon"
              name="chevron-down-outline"
          ></ion-icon>
          <ion-icon
              v-show="!isCollapsed"
              class="up-down big-icon"
              name="chevron-up-outline"
          ></ion-icon>
        </div>
        <h4 v-show="isCollapsed" class="more-less">
          Show more
        </h4>
        <h4 v-show="!isCollapsed" class="more-less">
          Show less
        </h4>
      </div>
      <div class="empty-block"></div>
      <a
          :class="{ 'empty-resume': resume == null }"
          :href="resume"
          class="see-resume"
          target="_blank"
      >
        <ion-icon class="big-icon" name="document-text-outline"></ion-icon>
        <p v-if="resume == null">No Resume</p>
        <p v-else>See Resume</p>
      </a>
    </div>
  </li>
</template>

<script>
import {onMounted, ref} from "vue";
import getProfile from "@/composables/Profile/getProfile";
import changeWishStatus from "@/composables/WishList/changeWishStatus";
import createAndSendChat from "@/composables/Msg/createAndSendChat";
import sendAcceptNote from "@/composables/Email/sendAcceptNote";
import getUrlAndName from "@/composables/Storage/getUrlAndName";
import getWishResume from "@/composables/Storage/getWishResume";
import getUser from "@/composables/getUser";
import {createToast} from "mosha-vue-toastify";

export default {
  props: ["candidate", "wishData"],
  async setup(props) {
    const {user} = getUser();
    const profile = ref(null);
    const isCollapsed = ref(true);
    const resetTrigger = ref(false);
    const profilePic = ref(null);
    const resume = ref(null);
    const statusHolder = ref(props.candidate.status);
    const confirmDecline = ref(false);
    const confirmAccept = ref(false);
    const isLoaded = ref(false);

    onMounted(async () => {
      await getProfile(profile, user.value.uid);
      await getUrlAndName(profilePic, null, props.candidate.uid, "Images");
      if (props.candidate.isDefaultResume){
        await getUrlAndName(resume, null, props.candidate.uid, "Resumes");
      } else {
        await getWishResume(resume, null, props.candidate.uid, props.wishData.wishId);
      }
      isLoaded.value = true;
    });


    const modifyDate = (dateHold) => {
      return (
          dateHold.getMonth() +
          1 +
          "/" +
          dateHold.getDate() +
          "/" +
          dateHold.getFullYear()
      );
    };

    const changeStatus = async (decision) => {
      await changeWishStatus(props.wishData.companyId, props.wishData.wishId, props.candidate.id, props.candidate.uid, user.value.uid, decision)
      if (decision == "granted") {
        await sendAcceptNote(
            props.candidate.firstName,
            props.candidate.email,
            profile.value.firstName + " " + profile.value.lastName,
            {position: props.wishData.position, company: props.wishData.company}
        );
        await createAndSendChat(props.candidate.uid, user.value.uid,
            "Hi, " + props.candidate.firstName + " " + props.candidate.lastName + ". I am glad to tell you that your wish for the position, " + props.wishData.position + ", at " + props.wishData.company + " has been granted. Please reply for further discussion with me. Thank you!"
        );
      }
    };

    // create notification
    const acceptCandidateNotification = () => {
      createToast({
        title: "Candidate accepted!",
        description: 'You have successfully accepted the wish of this candidate.'
      }, {
        type: "success"
      })
    }

    const acceptCandidate = () => {
      acceptCandidateNotification();
    }

    return {
      isLoaded,
      profilePic,
      resume,
      isCollapsed,
      statusHolder,
      resetTrigger,
      modifyDate,
      changeStatus,
      confirmDecline,
      confirmAccept,
      acceptCandidateNotification,
      acceptCandidate
    };
  },
};
</script>

<style scoped>
* {
  font-size: 100%;
  font-weight: 400;
}

.good-line-top {
  margin: 0vh;
}

.good-line-bot {
  margin-bottom: 2vh;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.line-invisible {
  opacity: 0;
  transition: opacity 0.25s ease;
}

.wish-candidate-card {
  background-color: white;
  border: 2px rgb(221, 221, 221) solid;
  border-radius: 1vh;
  padding: 1.5vh 1.5vw;
  margin: 2vh 0;
  animation: fadein 0.5s;
}

.wish-candidate-card-profile {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5vh;
}

.wish-candidate-card-profile-image {
  flex: 0 1 8%;
  display: flex;
  align-items: center;
}

.wish-candidate-card-profile-info {
  flex: 0 1 62%;
}

.wish-candidate-card-profile-info h3 {
  margin-bottom: 1.5vh;
}

.profile-info-second-line h4 {
  font-size: 90%;
  background-color: rgb(238, 238, 238);
  display: inline-block;
  padding: 1vh 1vw;
  border-radius: 1vh;
  margin-right: 1vw;
}

.wish-candidate-card-profile-image img {
  height: 4vw;
  width: 4vw;
}

.wish-candidate-card-profile-btns {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.wish-candidate-card-profile-status {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadein 0.5s;
}

.wish-candidate-card-profile-status div {
  flex: 0 0 4vw;
  padding-left: 10vw;
  padding-right: 1vw;
}

.wish-candidate-card-profile-status h3 {
  font-weight: 400;
  font-size: 100%;
  flex: 1 0 auto;
  padding: 0 0;
}

.decline-accept {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}

.decline-accept-hidden {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.confirm-btns {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}

.confirm-btns-hidden {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.dashboard-detail-decline-btn {
  width: 100px;
  padding: 1vh 1vw;
  border: 1.5px white solid;
  color: black;
  background-color: white;
  border-radius: 0.5vw;
  margin-right: 15px;
  font-size: 100%;
  transition: width 0.5s ease;
  overflow: hidden;
  border-color: rgb(252, 91, 91);
  color: rgb(252, 91, 91);
  cursor: pointer;
}

.dashboard-detail-decline-btn:hover {
  cursor: pointer;
}

.dashboard-detail-decline-btn p {
  display: inline;
  animation: fadein 0.5s;
}

.confirm-decline {
  border-color: rgb(252, 91, 91);
  color: rgb(252, 91, 91);
  width: 215px;
  transition: width 0.5s ease;
}

.confirm-decline:hover {
  border-color: red;
  color: red;
}

.dashboard-detail-accept-btn {
  width: 100px;
  padding: 1vh 1vw;
  border: 1.5px #cca2d8 solid;
  color: #cca2d8;
  border-radius: 0.5vw;
  background-color: white;
  font-size: 100%;
  transition: width 0.5s ease;
}

.dashboard-detail-accept-btn:hover {
  border-color: #c069d8;
  color: #c069d8;
  cursor: pointer;
}

.dashboard-detail-accept-btn p {
  display: inline;
}

.confirm-accept {
  width: 215px;
  transition: width 0.5s ease;
}

.collapse-content-container {
  max-height: 100vh;
  transform-origin: top;
  transition: all 1s ease;
  overflow: hidden;
}

.collapse-content-containerFolded {
  max-height: 0vh;
  transform-origin: top;
  transition: all 0.5s ease;
  overflow: hidden;
}

.profile-detail-content {
  padding: 1vh 0 2vh 0;
}

.about {
  padding-bottom: 1vh;
}

.about h3 {
  font-size: 120%;
  line-height: 5vh;
}

.about h4 {
  font-size: 90%;
  font-weight: 50;
}

.my-exp h2 {
  font-size: 120%;
  line-height: 3vh;
}

.my-exp h4 {
  font-size: 90%;
  font-weight: 50;
}

.expand-collapse-resume {
  width: 100%;
  display: flex;
}

.expand-collapse {
  flex: 0 0 300px;
  width: 100%;
  display: flex;
  color: grey;
}

.expand-collapse:hover {
  color: black;
  cursor: pointer;
}

.expand-collapse-arrow {
  flex: 0 0 30px;
  align-self: center;
  transition: all 0.25s ease;
}

.expand-collapse h4 {
  font-weight: 75;
  flex: 1 0 auto;
  align-self: center;
  transition: all 0.25s ease;
}

.empty-block {
  flex: 1 1 auto;
}

.see-resume {
  color: black;
  flex: 0 0 150px;
  display: flex;
  align-self: center;
}

.see-resume:hover {
  cursor: pointer;
  color: #cca2d8;
}

.empty-resume:hover {
  cursor: auto;
  color: black;
}

.see-resume ion-icon {
  align-self: center;
  flex: 0 0 auto;
  margin: 0 5px 0 15px;
  font-size: 125%;
}

.see-resume p {
  flex: 0 0 auto;
  padding: 0 5px;
}

.experience {
  list-style: none;
}

.my-exp-top {
  display: flex;
  align-items: center;
}

.my-exp-top h2 {
  flex: 0 1 70%;
}

.my-exp-top h4 {
  flex: 1 0 auto;
}

.exp-title h3 {
  font-size: 120%;
  line-height: 5vh;
}

.exp-title h4 {
  font-size: 90%;
  font-weight: 50;
}

@media screen and (max-width: 630px) {
  .wish-candidate-card-profile-info {
    flex: 1 1 62%;
  }

  .wish-candidate-card-profile-image {
    display: none;
  }

  .wish-candidate-card-profile-btns {
    justify-content: center;
  }

  .expand-collapse {
    flex-basis: 100px;
  }
}
</style>
